(function($){


    $(function(){

        //add the increase and decrease buttons to the cart
        var quantityBox = $('.bc-uatc-custom-qty-container .bc-atc-qty-input');
        $('<span class="bc-atc-qty bc-atc-qty-changer bc-atc-qty-decrease">-</span>').insertBefore(quantityBox);
        $('<span class="bc-atc-qty bc-atc-qty-changer bc-atc-qty-increase">+</span>').insertAfter(quantityBox);



        //function for the add and subtract quantity button
        $(document).on('click', '.bc-atc-qty-decrease', function(){
            let quantityBox = $(this).closest('form').find('.bc-atc-qty-input').first();
            let currentQuantity = parseInt(quantityBox.val());
            if (quantityBox.val() > 1)
                quantityBox.val(currentQuantity - 1);
        });

        //function for the add and subtract quantity button
        $(document).on('click', '.bc-atc-qty-increase', function(){
            let quantityBox = $(this).closest('form').find('.bc-atc-qty-input').first();
            let currentQuantity = parseInt(quantityBox.val());
            let max = parseInt(quantityBox.attr('max'));

            if (Number.isInteger(max))
            {
                if (quantityBox.val() < max)
                {
                    quantityBox.val(currentQuantity + 1);
                }
            } else
            {
                quantityBox.val(currentQuantity + 1);
            }

        });


    });



})(jQuery);